/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable comma-dangle */
import React, { useState, useCallback, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";

function InvoicesTable({ data, productTypes, adminUsers }) {
  const { t } = useTranslation();
  const imagesUrl = process.env.REACT_APP_IMAGES_URL;
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);
  const pages = useSelector((state) => state.auth?.userData?.Pages);
  const isPageRouteValid = (pageRoute) => {
    return pages?.some((page) => page.PageRouting === pageRoute);
  };
  // console.log(productTypes);
  useEffect(() => {}, []);
  return (
    <div className="mt-3 w-100" style={{ overflowX: "scroll" }}>
      <Table striped bordered hover className="w-100 mt-5 table-responsive mb-0">
        <thead className="text-white" style={{ backgroundColor: "#333" }}>
          <tr>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.dateOfInvoice")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.userName")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.clientName")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.carNumber")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.driverName")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.driverIdNumber")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.productType")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.latestKmsCounter")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.litersTaken")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.totalInvoiceValue")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.netDebt")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.pumpImage")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("dieselInvoice.kmsCounterImage")}
            </th>
            {((isPageRouteValid("editDieselInvoice") && pageActions?.includes("dieselInvoiceSearch_Update")) || isPageRouteValid("invoiceHistory")) && (
              <th
                className="bg-dark text-white"
                style={{ verticalAlign: "middle" }}
              >
                {t("search.options")}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((invoice) => (
            <tr key={invoice.Id}>
              <td>{invoice.CreationDate?.substring(0, 10)}</td>
              <td>
                {
                  adminUsers?.find((us) => us?.Id === invoice.CreatedBy)
                    ?.FullName
                }
              </td>
              <td>{invoice.ClientName}</td>
              <td>{invoice.Car?.CarNumber}</td>
              <td>{invoice.DriverName}</td>
              <td>{invoice.DriverNationalId}</td>
              <td>
                {
                  productTypes?.find(
                    (st) => st.Id === invoice.DieselProductTypeId
                  )?.TypeName
                }
              </td>
              <td>{invoice.KMSCounterToday}</td>
              <td>{invoice.LitersTakenByCar}</td>
              <td>{invoice.TotalInvoiceValue}</td>
              <td>{invoice.ClientNetDept}</td>
              <td>
                <img
                  src={`${imagesUrl}${invoice.GasolinePumpDataImagePath}`}
                  alt="pump Image"
                  style={{ width: "100px" }}
                />
              </td>
              <td>
                {/* <p>{`${imagesUrl}${invoice.CarKMSCounterImagePath}`}</p> */}
                <img
                  src={`${imagesUrl}${invoice.CarKMSCounterImagePath}`}
                  alt="Kms counter Image"
                  style={{ width: "100px" }}
                />
              </td>
              {((isPageRouteValid("editDieselInvoice") && pageActions?.includes("dieselInvoiceSearch_Update")) || isPageRouteValid("invoiceHistory")) && (
                <td>
                  <div className="d-flex">
                    {isPageRouteValid("editDieselInvoice") && (
                      <Button
                        size="sm"
                        variant="success"
                        className="me-2"
                        disabled={!isPageRouteValid("editDieselInvoice")}
                      >
                        <Link
                          className="text-white"
                          to={`/editDieselInvoice/${invoice.Id}`}
                        >
                          {t("dieselInvoice.editInvoice")}
                        </Link>
                      </Button>
                    )}
                    {isPageRouteValid("invoiceHistory") && (
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        disabled={!isPageRouteValid("invoiceHistory")}
                      >
                        <Link
                          className="outline-secondary"
                          to={`/invoiceHistory/${invoice.Id}`}
                        >
                          {t("search.view")}
                        </Link>
                      </Button>
                    )}
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
export default InvoicesTable;
