/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useRef, useState } from "react";
import { Label } from "reactstrap";
import { FaChevronDown } from "react-icons/fa";
import "./multiSelect.css";

function MultiSelect({
  options,
  label,
  selectedItems,
  setSelectedItems,
  getOptionLabel,
  getOptionValue,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const multiSelectRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
    if (!isOpen) {
      setSearchTerm(""); // Clear search term when dropdown is opened
    }
  };

  const handleCheckboxChange = (option) => {
    const isSelected = selectedItems.some(
      (item) => getOptionValue(item) === getOptionValue(option),
    );
    if (isSelected) {
      // Remove item if it's already selected
      setSelectedItems(
        selectedItems.filter(
          (item) => getOptionValue(item) !== getOptionValue(option),
        ),
      );
    } else {
      // Add item if it's not selected
      setSelectedItems([...selectedItems, option]);
    }
  };

  const handleClickOutside = (event) => {
    if (
      multiSelectRef.current &&
      !multiSelectRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for outside clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Filter options based on search term
  const filteredOptions = options?.filter((option) =>
    getOptionLabel(option)
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  );

  return (
    <div ref={multiSelectRef} className={`multi-select ${isOpen ? "open" : ""}`}>
      <Label>{label}</Label>
      <div
        onClick={toggleDropdown}
        className={`multi-select-input ${isOpen ? "focused" : ""}`}
      >
        {selectedItems.length === 0
          ? "Select options"
          : selectedItems.map((item) => getOptionLabel(item)).join(", ")}
        <FaChevronDown className="dropdown-icon" />
      </div>
      {isOpen && (
        <div className="multi-select-dropdown">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
            className="multi-select-search" // Add a class for styling
          />
          {filteredOptions.map((option) => (
            <div
              key={getOptionValue(option)}
              className="multi-select-option"
              onClick={() => handleCheckboxChange(option)} // Select on row click
            >
              <input
                type="checkbox"
                checked={selectedItems?.some(
                  (item) => getOptionValue(item) === getOptionValue(option),
                )}
                readOnly // Prevent checking through the checkbox
              />
              <span>{getOptionLabel(option)}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MultiSelect;
