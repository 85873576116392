/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable arrow-parens */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
/* eslint-disable comma-dangle */
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Webcam from "react-webcam";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { FaCamera, FaSync, FaFileImage } from "react-icons/fa";
import { Col, Form, Row } from "reactstrap";
import Select from "react-select";
import { toast } from "react-hot-toast";
import Loading from "../../components/Loading";

function CapturePhotoModal({ isOpen, onClose, onCapture }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const [facingMode, setFacingMode] = useState("environment"); // "user" for front camera, "environment" for back camera
  const [showSwitchButton, setShowSwitchButton] = useState(false); // Show switch button only when both modes are available

  useEffect(() => {
    if (navigator?.mediaDevices?.enumerateDevices) {
      // Get the list of available video input devices (cameras)
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        // Check if both front and back cameras are available
        const frontCamera = devices.find(
          (device) =>
            device.kind === "videoinput" && device.label.includes("front")
        );
        const backCamera = devices.find(
          (device) =>
            device.kind === "videoinput" && device.label.includes("back")
        );

        // Set the initial facing mode based on device availability
        if (frontCamera && backCamera) {
          setFacingMode("environment");
          setShowSwitchButton(true); // Show the switch button
        } else if (backCamera) {
          setFacingMode("environment");
          setShowSwitchButton(true); // Show the switch button
        } else {
          setFacingMode("user");
          setShowSwitchButton(true); // Show the switch button
        }
      });
    } else {
      console.warn("enumerateDevices is not supported by this browser.");
      // Set default values or show a message to the user
      setFacingMode("user");
      setShowSwitchButton(false);
    }
  }, []);
  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    onCapture(imageSrc);
  };
  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const videoConstraints = {
    facingMode,
  };

  return (
    <Modal show={isOpen} fullscreen onHide={onClose}>
      <Modal.Header closeButton>
        {/* <Modal.Title>Modal</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center my-3">
          <Col md={6} lg={4} className="">
            <Webcam
              className="w-100"
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
            <div className="d-flex justify-content-between mb-3">
              <div className="w-25"></div>
              <div className="text-center w-50">
                {" "}
                <button
                  className="main-button primary btn d-block m-auto"
                  onClick={capturePhoto}
                >
                  <FaCamera size={24} style={{ marginRight: "8px" }} />
                  Capture Photo
                </button>
              </div>
              <div className="text-end w-25">
                <Button variant="secondary" size="sm" onClick={toggleCamera}>
                  <FaSync size={24} />
                </Button>
              </div>
            </div>

            <Button
              variant="secondary"
              onClick={onClose}
              className="my-3 d-block m-auto"
            >
              Close
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
export default CapturePhotoModal;
