/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable comma-dangle */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Form, Row } from "reactstrap";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { toast } from "react-hot-toast";
import InputField from "../../components/input";
import BinaryInput from "../../components/binary-input";
import Loading from "../../components/Loading";
import Api from "../../ymc-sdk/services";
import schema from "./schemaClientDetails";
import SettingBinaryInput from "../../components/binary-input/setting-input";
import DeleteClient from "./deleteClient";

// eslint-disable-next-line object-curly-newline
function ClientDetails({
  newClient,
  editClient,
  industries,
  regions,
  productCategories,
  clientStatus,
  productTypes,
  assignedUsers,
  isLoading,
  clientData,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [industry, setIndustry] = useState(null);
  const [region, setRegion] = useState(null);
  const [productGategory, SetProductGategory] = useState(null);
  const [status, setStatus] = useState(null);
  const [productType, setProductType] = useState({});
  const [assignedUser, setAssignedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const UserId = localStorage.getItem("UserId");
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);
  const navigate = useNavigate();
  const {
    reset,
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema(editClient && clientData?.ClientCode)),
  });
  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 8 && charCode !== 0 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (editClient && clientData && !isLoading) {
      // console.log(clientData);
      const selectedRegion = regions.find(
        (region) => region.Id === clientData.RegionId
      );
      setRegion(selectedRegion);
      const selectedIndustry = industries.find(
        (industry) => industry.Id === clientData.IndustryId
      );
      setIndustry(selectedIndustry);
      const selectedProductCategory = productCategories.find(
        (productCategory) => productCategory.Id === clientData.ProductCategoryId
      );
      SetProductGategory(selectedProductCategory);
      const selectedAssignedUser = assignedUsers.find(
        (assignedUser) => assignedUser.Id === clientData.AssignedUserId
      );
      setAssignedUser(selectedAssignedUser);
      const selectedstatus = clientStatus.find(
        (st) => st.Id === clientData.ClientStatusId
      );
      setStatus(selectedstatus);
      const selectedProductType = productTypes.find(
        (st) => st.Id === clientData.ProductTypePreferedId
      );
      setProductType(selectedProductType);
      reset(clientData);
      setValue(
        "LastContactDateWithCompany",
        clientData?.LastContactDateWithCompany
          ? clientData?.LastContactDateWithCompany?.substring(0, 10)
          : null
      );
      setValue(
        "IsPriceSentOffer",
        clientData?.IsPriceSentOffer
          ? "true"
          : clientData?.IsPriceSentOffer !== null
          ? "false"
          : undefined
      );
      // setValue(
      //   "IsDatePriceSentOffer",
      //   clientData?.IsDatePriceSentOffer
      //     ? "true"
      //     : clientData?.IsDatePriceSentOffer !== null
      //     ? "false"
      //     : ""
      // );
      setValue(
        "IsPossibleToProvideServiceToCompany",
        clientData?.IsPossibleToProvideServiceToCompany
          ? "true"
          : clientData?.IsPossibleToProvideServiceToCompany !== null
          ? "false"
          : ""
      );
      setValue(
        "AttachInEmailPhotoGasPumpTaken",
        clientData?.AttachInEmailPhotoGasPumpTaken
          ? "true"
          : clientData?.AttachInEmailPhotoGasPumpTaken !== null
          ? "false"
          : ""
      );
    }
  }, [
    clientData,
    editClient,
    regions,
    industries,
    productCategories,
    assignedUsers,
    clientStatus,
  ]);
  // console.log(region);
  const onSubmit = (data) => {
    // if (data?.ClientStatusId === "c8a39ac7-9871-422c-b11d-6e3989395aad") {
    //   setError("ClientCode", {
    //     type: "manual",
    //     message: t("auth.required"),
    //   });
    // }
    if (newClient) {
      data.CreatedBy = UserId;
      // console.log(data);
      // setLoading(true);
      Api.clients
        .creteClient({ UserId }, data)
        .then((res) => {
          setLoading(false);
          // console.log(res?.Data);
          toast.success(t("shared.addedSuccessfully"));
          navigate(`/clients/${res?.Data}`);
        })
        .catch((err) => {
          setLoading(false);
          // console.log(err);
          toast.error(t("shared.sorrySomethingWrong"));
        });
    }
    if (editClient) {
      let updateData = { ...clientData };
      updateData.LastUserModifyId = UserId;
      updateData = { ...updateData, ...data };
      // console.log("mmmm", updateData);
      Api.clients
        .updateClient(clientData?.Id, { UserId }, updateData)
        .then((res) => {
          setLoading(false);
          // console.log(res);
          toast.success(t("shared.editedSuccessfully"));
          navigate(`/clients/${clientData?.Id}`);
        })
        .catch((err) => {
          setLoading(false);
          // console.log(err);
          toast.error(t("shared.sorrySomethingWrong"));
        });
    }
  };
  return (
    <div className="auth-modal body-style mt-3 p-5">
      {newClient ? (
        <Helmet>
          <title>{`YMC Group | ${t("clients.addNewClient")}`}</title>
        </Helmet>
      ) : (
        <Helmet>
          <title>{`YMC Group | ${t("clients.editClientData")} | ${
            clientData?.ClientName || " "
          }`}</title>
        </Helmet>
      )}

      <div className="text-center mb-4">
        {newClient ? (
          <h2>{t("clients.addNewClient")}</h2>
        ) : (
          <h2>{t("clients.editClientData")}</h2>
        )}
      </div>
      {editClient && pageActions?.includes("editClient_Delete") && (
        <div className="d-flex justify-content-end">
          <Button
            size="md"
            variant="danger"
            className="me-2"
            disabled={!pageActions?.includes("editClient_Delete")}
            onClick={() => setModalShow(true)}
          >
            {t("clients.deleteClient")}
          </Button>
        </div>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="gy-3 gx-4">
          {/* client code */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ClientCode">
              {t("clients.clientCode")}
              {status?.label === "Current Client" && (
                <span className="text-danger"> * </span>
              )}
            </label>
            <InputField
              name="ClientCode"
              placeholder={t("clients.clientCode")}
              errors={errors}
              type="text"
              readOnly={editClient && clientData?.ClientCode}
              onKeyPress={handleKeyPress}
              {...register("ClientCode")}
            />
          </Col>
          {/* client Name */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ClientName">
              {t("clients.clientName")}
              <span className="text-danger"> * </span>
            </label>
            <InputField
              name="ClientName"
              placeholder={t("clients.clientName")}
              errors={errors}
              type="text"
              {...register("ClientName")}
            />
          </Col>
          {/* regions */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="RegionId">
              {t("clients.region")}
              <span className="text-danger"> * </span>
            </label>
            <Controller
              name="RegionId"
              control={control}
              render={({ regionField }) => (
                <Select
                  {...regionField}
                  name="RegionId"
                  defaultValue={region}
                  value={region}
                  onChange={(selectedOption) => {
                    setRegion(selectedOption); // Update the selected option in the state
                    setValue("RegionId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                  }}
                  options={regions}
                  placeholder={t("clients.region")}
                  isSearchable
                  isClearable
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.Id}
                  className={errors.RegionId && !region ? "error-border" : ""}
                />
              )}
            />
            {errors.RegionId && !region ? (
              <p className="error-label">{errors.RegionId.message}</p>
            ) : (
              <></>
            )}
          </Col>
          {/* client Address */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="Address">
              {t("clients.address")}
              <span className="text-danger"> * </span>
            </label>
            <InputField
              name="Address"
              placeholder={t("clients.address")}
              errors={errors}
              type="text"
              {...register("Address")}
            />
          </Col>
          {/* industries */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="IndustryId">
              {t("clients.industry")}
              <span className="text-danger"> * </span>
            </label>
            <Controller
              name="IndustryId"
              control={control}
              render={({ industryField }) => (
                <Select
                  {...industryField}
                  name="IndustryId"
                  defaultValue={industry}
                  value={industry}
                  onChange={(selectedOption) => {
                    setIndustry(selectedOption); // Update the selected option in the state
                    setValue("IndustryId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                  }}
                  options={industries}
                  placeholder={t("clients.industry")}
                  isSearchable
                  isClearable
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.Id}
                  className={
                    errors.IndustryId && !industry ? "error-border" : ""
                  }
                />
              )}
            />
            {errors.IndustryId && !industry ? (
              <p className="error-label">{errors.IndustryId.message}</p>
            ) : (
              <></>
            )}
          </Col>
          {/* additional industry details */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="IndustryAdditionalNotes">
              {t("clients.additionalIndustryDetails")}
            </label>
            <InputField
              name="IndustryAdditionalNotes"
              placeholder={t("clients.additionalIndustryDetails")}
              errors={errors}
              type="text"
              {...register("IndustryAdditionalNotes")}
            />
          </Col>
          {/* client phoneNumber */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="PhoneNumber">
              {t("clients.phoneNumber")}
              {/* <span className="text-danger"> * </span> */}
            </label>
            <InputField
              name="PhoneNumber"
              placeholder={t("clients.phoneNumber")}
              errors={errors}
              type="tel"
              {...register("PhoneNumber")}
            />
          </Col>
          {/* product categories */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ProductCategoryId">
              {t("clients.productCategory")}
              <span className="text-danger"> * </span>
            </label>
            <Controller
              name="ProductCategoryId"
              control={control}
              render={({ productGategoryField }) => (
                <Select
                  {...productGategoryField}
                  name="ProductCategoryId"
                  defaultValue={productGategory}
                  value={productGategory}
                  onChange={(selectedOption) => {
                    SetProductGategory(selectedOption); // Update the selected option in the state
                    setValue("ProductCategoryId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                  }}
                  options={productCategories}
                  placeholder={t("clients.productCategory")}
                  isSearchable
                  isClearable
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.Id}
                  className={
                    errors.ProductCategoryId && !productGategory
                      ? "error-border"
                      : ""
                  }
                />
              )}
            />
            {errors.ProductCategoryId && !productGategory ? (
              <p className="error-label">{errors.ProductCategoryId.message}</p>
            ) : (
              <></>
            )}
          </Col>
          {/* creditLimit */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="CreditLimit">
              {t("clients.creditLimit")}
            </label>
            <InputField
              name="CreditLimit"
              placeholder={t("clients.creditLimit")}
              errors={errors}
              type="number"
              default={0}
              readOnly={editClient && Number.isFinite(clientData?.CreditLimit)}
              {...register("CreditLimit")}
            />
          </Col>
          {/* contractual Payment Term */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ContractualPaymentTerm">
              {t("clients.contractualPaymentTerm")}
            </label>
            <InputField
              name="ContractualPaymentTerm"
              placeholder={t("clients.contractualPaymentTerm")}
              errors={errors}
              type="number"
              readOnly={
                editClient &&
                Number.isFinite(clientData?.ContractualPaymentTerm)
              }
              {...register("ContractualPaymentTerm")}
            />
          </Col>
          {/* actual Payment Period */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ActualPaymentPeriod">
              {t("clients.actualPaymentPeriod")}
            </label>
            <InputField
              name="ActualPaymentPeriod"
              placeholder={t("clients.actualPaymentPeriod")}
              errors={errors}
              type="number"
              min={0}
              {...register("ActualPaymentPeriod")}
            />
          </Col>
          {/* sales agent */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="AssignedUserId">
              {t("clients.salesAgent")}
              <span className="text-danger"> * </span>
            </label>
            <Controller
              name="AssignedUserId"
              control={control}
              render={({ assignedUserField }) => (
                <Select
                  {...assignedUserField}
                  name="AssignedUserId"
                  defaultValue={assignedUser}
                  value={assignedUser}
                  onChange={(selectedOption) => {
                    setAssignedUser(selectedOption); // Update the selected option in the state
                    setValue("AssignedUserId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                  }}
                  options={assignedUsers}
                  placeholder={t("clients.salesAgent")}
                  isSearchable
                  isClearable
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.Id}
                  className={
                    errors.AssignedUserId && !assignedUser ? "error-border" : ""
                  }
                />
              )}
            />
            {errors.AssignedUserId && !assignedUser ? (
              <p className="error-label">{errors.AssignedUserId.message}</p>
            ) : (
              <></>
            )}
          </Col>
          {/* client status */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ClientStatusId">
              {t("clients.clientStatus")}
              <span className="text-danger"> * </span>
            </label>
            <Controller
              name="ClientStatusId"
              control={control}
              render={({ statusField }) => (
                <Select
                  {...statusField}
                  name="ClientStatusId"
                  defaultValue={status}
                  value={status}
                  onChange={(selectedOption) => {
                    setStatus(selectedOption); // Update the selected option in the state
                    setValue("ClientStatusId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                  }}
                  options={clientStatus}
                  placeholder={t("clients.clientStatus")}
                  isSearchable
                  isClearable
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.Id}
                  className={
                    errors.ClientStatusId && !status ? "error-border" : ""
                  }
                />
              )}
            />
            {errors.ClientStatusId && !status ? (
              <p className="error-label">{errors.ClientStatusId.message}</p>
            ) : (
              <></>
            )}
          </Col>
          {/* Head Office Address */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ManagementAddress">
              {t("clients.headOfficeAddress")}
            </label>
            <InputField
              name="ManagementAddress"
              placeholder={t("clients.headOfficeAddress")}
              errors={errors}
              type="text"
              {...register("ManagementAddress")}
            />
          </Col>
          {/* Notes */}
          <Col md={6} className="">
            <label htmlFor="Notes" className="mb-2">
              {t("clients.notes")}
            </label>
            <br />
            <textarea
              className="form-control"
              aria-label={t("clients.notes")}
              placeholder={t("clients.notes")}
              name="Notes"
              rows="3"
              type="text"
              {...register("Notes")}
            />
          </Col>
          {/* last Contact Date */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="LastContactDateWithCompany">
              {t("clients.lastContactDateWithCompany")}
            </label>
            <InputField
              name="LastContactDateWithCompany"
              errors={errors}
              type="date"
              {...register("LastContactDateWithCompany")}
            />
          </Col>
          {/* product types */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ProductTypePreferedId">
              {t("clients.productTypePrefered")}
            </label>
            <Controller
              name="ProductTypePreferedId"
              control={control}
              render={({ productTypeField }) => (
                <Select
                  {...productTypeField}
                  name="ProductTypePreferedId"
                  defaultValue={productType}
                  value={productType}
                  onChange={(selectedOption) => {
                    setProductType(selectedOption); // Update the selected option in the state
                    setValue("ProductTypePreferedId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                  }}
                  options={productTypes}
                  placeholder={t("clients.productTypePrefered")}
                  isSearchable
                  isClearable
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.Id}
                  className={
                    errors.ProductTypePreferedId && !productType
                      ? "error-border"
                      : ""
                  }
                />
              )}
            />
            {errors.ProductTypePreferedId && !productType ? (
              <p className="error-label">
                {errors.ProductTypePreferedId.message}
              </p>
            ) : (
              <></>
            )}
          </Col>
          {/* price offer sent */}
          <Col md={6} className="">
            <label htmlFor="IsPriceSentOffer" className="mb-2 mt-4">
              {t("clients.priceOfferSent")}
              <span className="text-danger"> * </span>
            </label>
            <SettingBinaryInput
              errors={errors}
              id={t("shared.yes")}
              label={t("shared.yes")}
              categoryId="group1"
              type="radio"
              value
              name="IsPriceSentOffer"
              {...register("IsPriceSentOffer")}
              // hideError
            />
            <SettingBinaryInput
              errors={errors}
              id={t("shared.no")}
              label={t("shared.no")}
              categoryId="group1"
              type="radio"
              value={false}
              name="IsPriceSentOffer"
              {...register("IsPriceSentOffer")}
              // hideError
            />
          </Col>
          {/* Date of price offer sent */}
          {/* <Col md={6} className="">
            <label htmlFor="IsDatePriceSentOffer" className="mb-2 mt-4">
              {t("clients.dateOfPriceOfferSent")}
            </label>
            <SettingBinaryInput
              errors={errors}
              id={t("shared.yes")}
              label={t("shared.yes")}
              categoryId="group2"
              type="radio"
              value
              name="IsDatePriceSentOffer"
              {...register("IsDatePriceSentOffer")}
            />
            <SettingBinaryInput
              errors={errors}
              id={t("shared.no")}
              label={t("shared.no")}
              categoryId="group2"
              type="radio"
              value={false}
              name="IsDatePriceSentOffer"
              {...register("IsDatePriceSentOffer")}
            />
          </Col> */}
          {/* Possible To Provide Service To Company */}
          <Col md={6} className="">
            <label
              htmlFor="IsPossibleToProvideServiceToCompany"
              className="mb-2 mt-4"
            >
              {t("clients.possibleToProvideServiceToCompany")}
            </label>
            <SettingBinaryInput
              errors={errors}
              id={t("shared.yes")}
              label={t("shared.yes")}
              categoryId="group3"
              type="radio"
              value
              name="IsPossibleToProvideServiceToCompany"
              {...register("IsPossibleToProvideServiceToCompany")}
            />
            <SettingBinaryInput
              errors={errors}
              id={t("shared.no")}
              label={t("shared.no")}
              categoryId="group3"
              type="radio"
              value={false}
              name="IsPossibleToProvideServiceToCompany"
              {...register("IsPossibleToProvideServiceToCompany")}
            />
          </Col>
          {/* client debt */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ClientDebt">
              {t("clients.netDebt")}
            </label>
            <InputField
              name="ClientDebt"
              placeholder={t("clients.netDebt")}
              errors={errors}
              type="number"
              default={0}
              readOnly={editClient && Number.isFinite(clientData?.ClientDebt)}
              {...register("ClientDebt")}
            />
          </Col>
          {/* security deposit value */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="SecurityDepositValue">
              {t("clients.securityDepositValue")}
            </label>
            <InputField
              name="SecurityDepositValue"
              placeholder={t("clients.securityDepositValue")}
              errors={errors}
              type="number"
              min={0}
              {...register("SecurityDepositValue")}
            />
          </Col>
          {/* Security deposit Bank check date and Notes */}
          <Col md={6} className="">
            <label htmlFor="SecurityDepositNotes" className="mb-2">
              {t("clients.securityDepositNotes")}
            </label>
            <br />
            <textarea
              className="form-control"
              aria-label={t("clients.securityDepositNotes")}
              placeholder={t("clients.securityDepositNotes")}
              name="SecurityDepositNotes"
              rows="3"
              type="text"
              {...register("SecurityDepositNotes")}
            />
          </Col>
          {/* Average quarterly consumption */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="AverageQuarterlyConsumption">
              {t("clients.averageQuarterlyConsumption")}
            </label>
            <InputField
              name="AverageQuarterlyConsumption"
              placeholder={t("clients.averageQuarterlyConsumption")}
              errors={errors}
              type="number"
              step="0.1"
              min={0}
              {...register("AverageQuarterlyConsumption")}
            />
          </Col>
          {/* Average simiAnnual consumption */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="AverageSimiAnnualConsumption">
              {t("clients.averageSimiAnnualConsumption")}
            </label>
            <InputField
              name="AverageSimiAnnualConsumption"
              placeholder={t("clients.averageSimiAnnualConsumption")}
              errors={errors}
              type="number"
              step="0.1"
              min={0}
              {...register("AverageSimiAnnualConsumption")}
            />
          </Col>
          {/* attachInEmailPhotoGasPumpTaken */}
          <Col md={6} className="">
            <label
              htmlFor="AttachInEmailPhotoGasPumpTaken"
              className="mb-2 mt-4"
            >
              {t("diesalClients.attachInEmailPhoto")}
            </label>
            <SettingBinaryInput
              errors={errors}
              id={t("shared.yes")}
              label={t("shared.yes")}
              categoryId="group4"
              type="radio"
              value
              name="AttachInEmailPhotoGasPumpTaken"
              {...register("AttachInEmailPhotoGasPumpTaken")}
            />
            <SettingBinaryInput
              errors={errors}
              id={t("shared.no")}
              label={t("shared.no")}
              categoryId="group4"
              type="radio"
              value={false}
              name="AttachInEmailPhotoGasPumpTaken"
              {...register("AttachInEmailPhotoGasPumpTaken")}
            />
          </Col>
          {/* email Standard Format */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="EmailStandardFormat">
              {t("diesalClients.emailStandardFormat")}
            </label>
            <InputField
              name="EmailStandardFormat"
              placeholder={t("diesalClients.emailStandardFormat")}
              errors={errors}
              type="text"
              {...register("EmailStandardFormat")}
            />
          </Col>
        </Row>

        <Row className="justify-content-center my-3">
          <Col md={6} lg={4} className="">
            {newClient
              ? pageActions?.includes("addNewClient_Create") && (
                  <button
                    type="submit"
                    className="main-button primary btn d-block w-50 m-auto"
                    disabled={
                      // eslint-disable-next-line operator-linebreak
                      !pageActions?.includes("addNewClient_Create")
                    }
                  >
                    {t("clients.addClient")}
                  </button>
                )
              : pageActions?.includes("editClient_Update") && (
                  <button
                    type="submit"
                    className="main-button primary btn d-block w-50 m-auto"
                    disabled={
                      // eslint-disable-next-line operator-linebreak

                      !pageActions?.includes("editClient_Update")
                    }
                  >
                    {t("clients.editClient")}
                  </button>
                )}
          </Col>
        </Row>
      </Form>
      <DeleteClient
        show={modalShow}
        clientId={clientData?.Id}
        onHide={() => setModalShow(false)}
      />
      {isLoading && <Loading />}
      {loading && <Loading />}
    </div>
  );
}
export default ClientDetails;
