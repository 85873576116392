/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { getHistory } from "../../ymc-sdk/redux/actions/searchOffer.actions";
import TableHistory from "./table";

function History() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { offerId } = useParams();
  const [loading, setLoading] = useState(false);
  const [offerData, setOfferData] = useState(null);
  const [offerHistory, setOfferHistory] = useState(null);

  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver() });
  useEffect(() => {
    dispatch(getHistory(offerId))
      .then((res) => {
        //   reset(res?.Data);
        setOfferHistory(res);
        // console.log(res, "historryyyyyyyyyyyy");
      })
      .catch((error) => {
        console.log(error);
      });
  }, [offerId]);

  const renderStatus = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Accepted";
      case 2:
        return "Rejected";
      default:
        return "Not Available";
    }
  };
  return (
    <div className="p-5">
      <Helmet>
        <title>{`YMC Group | ${t("priceOffer.offerEdits")} | ${t(
          "view.offerNo"
        )} | ${offerHistory?.[0]?.SerialNumber || " "}`}</title>
      </Helmet>
      {offerHistory && offerHistory.length && (
        <>
          <h2>
            {" "}
            {t("view.offerNo")} {offerHistory[0].SerialNumber}
          </h2>
          <Table striped bordered hover className="mt-5 table-responsive mb-0">
            <thead className="text-white" style={{ backgroundColor: "#333" }}>
              <tr>
                <th className="col-md-4"> {t("view.date")} </th>
                {/* <th className="col-md-4">Expected date to receive payment check </th> */}
                <th className="col-md-4"> {t("view.notes")} </th>
                <th className="col-md-4"> {t("view.status")} </th>
                <th className="col-md-4"> </th>
              </tr>
            </thead>
            <tbody>
              {offerHistory?.map((el) => (
                <tr key={el.PriceOfferId}>
                  <td className="col-md-4">
                    {" "}
                    {`${new Date(el?.LastModifiedDate).toLocaleString(
                      "en-IN"
                    )}`}
                  </td>
                  {/* <td className="col-md-4">{el.ExptectedDateToRecievePaymentCheck}</td> */}
                  <td className="col-md-4">{el.Notes}</td>
                  <td className="col-md-4">{renderStatus(el.Status)}</td>
                  <td className="col-md-4">
                    {el.AttachmentUrl ? (
                      <a
                        className="text-white"
                        href={`https://backendapi.ymcgroup.org${el.AttachmentUrl}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        {t("view.pdf")}
                      </a>
                    ) : (
                      el.RejectReason
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-5">
            <TableHistory />
          </div>
        </>
      )}
    </div>
  );
}

export default History;
