/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Label, Row } from "reactstrap";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../dieselInvoiceSearch/datePickerCustomStyle.css";
import MultiSelect from "./multiSelectComponent";

function CheckboxOption(props) {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null} // Prevent checkbox toggle manually; react-select handles it
        style={{ marginRight: 10 }}
      />
      {props.label}
    </components.Option>
  );
}

/* eslint-disable react/react-in-jsx-scope */
function PriceOfferSearchRoleSetting({
  clientsYMC,
  clients9,
  adminUsers,
  rolePriceOfferSetting,
}) {
  const { i18n, t } = useTranslation();
  const [userId, setUserId] = useState([]);
  const [selectClients, setSelectClients] = useState([]);
  const [selectClientsSystem9, setSelectClientsSystem9] = useState([]);

  return (
    <div>
      <h2 className="mt-4 py-5">
        {t("userSettings.editRoleSearchDataForPriceOfferSearch")}
      </h2>
      <Row className="gx-5 gy-3">
        <Col md={6}>
          <Label> {t("search.startDate")} </Label>
          {/* <Controller
            name="from"
            control={control}
            className="w-100"
            render={({ field }) => ( */}
          <DatePicker
            className="w-100 react-datepicker__input-container"
            selected=""
            placeholderText={t("dieselInvoice.startDate")}
            showTimeSelect
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            // {...field}
          />
          {/* )}
          /> */}
        </Col>
        <Col md={6}>
          <Label> {t("search.endDate")} </Label>
          {/* <Controller
            name="from"
            control={control}
            className="w-100"
            render={({ field }) => ( */}
          <DatePicker
            className="w-100 react-datepicker__input-container"
            selected=""
            placeholderText={t("dieselInvoice.endDate")}
            showTimeSelect
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            // {...field}
          />
          {/* )}
          /> */}
        </Col>
        <Col md={6}>
          <MultiSelect
            label={t("search.selectUserName")}
            options={adminUsers}
            selectedItems={userId}
            setSelectedItems={setUserId}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.Id}
          />
        </Col>
        <Col md={6}>
          <MultiSelect
            label={t("priceOffer.chooseClient")}
            options={clientsYMC}
            selectedItems={selectClients}
            setSelectedItems={setSelectClients}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.Id}
          />
        </Col>
        <Col md={6}>
          {/* (from system 9) */}
          <MultiSelect
            label={t("priceOffer.chooseAnOption")}
            options={clients9}
            selectedItems={selectClientsSystem9}
            setSelectedItems={setSelectClientsSystem9}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.Id}
          />
        </Col>
      </Row>
    </div>
  );
}
export default PriceOfferSearchRoleSetting;
