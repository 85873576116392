/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { Row, Col, Card } from "react-bootstrap";
import { AiFillCar } from "react-icons/ai"; // React icons example
import { FaIdCard } from "react-icons/fa";
import { getHistory } from "../../ymc-sdk/redux/actions/searchOffer.actions";
import Loading from "../../components/Loading";
import InvoiceHistoryTable from "./invoiceHistoryTable";
import Api from "../../ymc-sdk/services";
import AuthApiEndpoints from "../../ymc-sdk/services/auth.api";

function DieselInvoiceHistory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { invoiceId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [invoiceData, setInvoiveData] = useState(null);
  const [carData, setCarData] = useState(null);
  const [invoiceHistory, setInvoiceHistory] = useState(null);

  const { data: adminUsers, isLoading: adminUsersLoading } = useQuery(
    ["admin users"],
    () => AuthApiEndpoints.getSystemUsers(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );

  const { data: productTypes, isLoading: productTypesLoading } = useQuery(
    ["productTypes"],
    () => Api.lookupData.getDiesalProductTypes(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("industries", data);
        return data;
      },
      // eslint-disable-next-line comma-dangle
    }
  );

  useEffect(() => {
    setLoading(true);
    Api.diesalClients.Invoice.getInvoiceHistory(invoiceId)
      .then((res) => {
        // setLoading(false);
        //   reset(res?.Data);
        Api.diesalClients.car.getCarById(res?.[0]?.CarId).then((car) => {
          setLoading(false);
          setCarData(car?.Data);
        }).catch((error) => {
          setLoading(false);
        });
        setInvoiveData(res);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [invoiceId]);

  // console.log(invoiceData);

  return (
    <>
      <div className="auth-modal body-style mt-3 p-5">
        <Helmet>
          <title>{`YMC Group | ${t("navBar.dieselInvoice")} | ${invoiceData?.ClientName || " "
            } | ${t("dieselInvoice.carNumber")} => ${invoiceData?.CarNumber || " "}`}</title>
        </Helmet>
        {invoiceData && (
          <>
            <h2 className="mb-4">
              {t("dieselInvoice.invoiceHistory")}
            </h2>
            {invoiceData?.length > 0 ? <Row className="mb-5">
              <Col md={4}>
                <Card className="shadow-sm mb-4">
                  <Card.Body>
                    <Card.Title className="text-muted">
                      {t("dieselInvoice.clientName")}
                    </Card.Title>
                    <Card.Text className="text-dark">
                      {invoiceData?.[0]?.ClientName}
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card className="shadow-sm mb-4">
                  <Card.Body>
                    <Card.Title className="text-muted">
                      {t("dieselInvoice.netDebt")}
                    </Card.Title>
                    <Card.Text className="text-dark">
                      {invoiceData?.[0]?.ClientNetDept}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card className="shadow-sm mb-4">
                  <Card.Body>
                    <Card.Title className="text-muted">
                      {t("dieselInvoice.carNumber")}
                    </Card.Title>
                    <Card.Text className="text-dark d-flex align-items-center">
                      {carData?.CarNumber }{" "}
                      <AiFillCar className="ms-2" />
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card className="shadow-sm mb-4">
                  <Card.Body>
                    <Card.Title className="text-muted">
                      {t("dieselInvoice.driverName")}
                    </Card.Title>
                    <Card.Text className="text-dark">
                      {invoiceData?.[0]?.DriverName}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card className="shadow-sm mb-4">
                  <Card.Body>
                    <Card.Title className="text-muted">
                      {t("dieselInvoice.driverIdNumber")}
                    </Card.Title>
                    <Card.Text className="text-dark d-flex align-items-center">
                      {invoiceData?.[0]?.DriverNationalId}{" "}
                      <FaIdCard className="ms-2" />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row> : <></>}
            <div className="px-1">
              {/* Pass the invoice history data to the table component */}
              <InvoiceHistoryTable data={invoiceData} users={adminUsers} productTypes={productTypes} />
            </div>
          </>
        )}
      </div>
      {isLoading && <Loading />}
      {(adminUsersLoading || productTypesLoading) && <Loading />}
    </>
  );
}

export default DieselInvoiceHistory;
