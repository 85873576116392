/* eslint-disable import/no-cycle */
import HttpHelpers from "./helpers";

const DiesalClientsApiEndpoints = {
  car: {
    createCar: (params, data) => {
      return HttpHelpers.authenticatedAxios
        .post("Car", data, { params })
        .then((response) => response.data);
    },
    deleteCar: (Id, params) => {
      return HttpHelpers.authenticatedAxios
        .delete(`Car/HardDelete/${Id}`, { params })
        .then((response) => response.data);
    },
    getCarById: (id) => {
      return HttpHelpers.authenticatedAxios
        .get(`Car/${id}`)
        .then((response) => response.data);
    },
  },
  driver: {
    createDriver: (params, data) => {
      return HttpHelpers.authenticatedAxios
        .post("Driver", data, { params })
        .then((response) => response.data);
    },
    getDriverById: (id) => {
      return HttpHelpers.authenticatedAxios
        .get(`Driver/${id}`)
        .then((response) => response.data);
    },
    getAllDrivers: () => {
      return HttpHelpers.authenticatedAxios
        .get("Driver", { all: true })
        .then((response) => response.data);
    },
    deleteDriver: (Id, params) => {
      return HttpHelpers.authenticatedAxios
        .delete(`Driver/HardDelete/${Id}`, { params })
        .then((response) => response.data);
    },
  },
  Invoice: {
    createInvoice: (params, data) => {
      return HttpHelpers.authenticatedAxios
        .post("ClientDiesel", data, { params })
        .then((response) => response.data);
    },
    getLastTimeKms: (params) => {
      return HttpHelpers.authenticatedAxios
        .get(`ClientDiesel/GetLastTimeKMS`, { params })
        .then((response) => response.data);
    },
    searchDieselClient: (params) => {
      return HttpHelpers.authenticatedAxios
        .get(`ClientDiesel/SearchDieselClient`, { params })
        .then((response) => response.data);
    },
    exportDieselClientToExcel: (params) => {
      return HttpHelpers.authenticatedAxios
        .get(`ClientDiesel/ExportDieselClientToExcel`, { params })
        .then((response) => response.data);
    },

    updateInvoice: (Id, params, data) => {
      return HttpHelpers.authenticatedAxios
        .put(`ClientDiesel/${Id}`, data, { params })
        .then((response) => response.data);
    },

    getInvoiceById: (id) => {
      return HttpHelpers.authenticatedAxios
        .get(`ClientDiesel/${id}`)
        .then((response) => response.data);
    },
    getInvoiceHistory: (id) => {
      return HttpHelpers.authenticatedAxios
        .get(`ClientDiesel/GetHistoricalData/${id}`)
        .then((response) => response.data);
    },
  },
};

export default DiesalClientsApiEndpoints;
