/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function InvoiceHistoryTable({ data, users, productTypes }) {
  const { t } = useTranslation();
  const imagesUrl = process.env.REACT_APP_IMAGES_URL;
  return (
    <div className="table-responsive">
      <Table striped bordered hover className="mt-4">
        <thead className="bg-dark text-white">
          <tr>
            <th>{t("dieselInvoice.dateOfInvoice")}</th>
            <th>{t("dieselInvoice.userName")}</th>
            <th>{t("dieselInvoice.productType")}</th>
            <th>{t("dieselInvoice.kmsCounterToday")}</th>
            <th>{t("dieselInvoice.litersTaken")}</th>
            <th>{t("dieselInvoice.totalInvoiceValue")}</th>
            <th>{t("dieselInvoice.pumpImage")}</th>
            <th>{t("dieselInvoice.kmsCounterImage")}</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <tr key={item}>
                <td>
                  {new Date(item?.LastModifiedDate).toLocaleString("en-IN")}
                </td>
                <td>
                  {users?.find((st) => st.Id === item.LastUserModifyId)?.FullName}
                </td>
                <td>
                  {productTypes?.find((st) => st.Id === item.DieselProductTypeId)?.TypeName}
                </td>
                <td>{item?.KMSCounterToday}</td>
                <td>{item?.LitersTakenByCar}</td>
                <td>{item?.TotalInvoiceValue}</td>
                <td>
                  <img
                    src={`${imagesUrl}${item?.GasolinePumpDataImagePath}`}
                    alt="pump Image"
                    style={{ width: "100px" }}
                  />
                </td>
                <td>
                  <img
                    src={`${imagesUrl}${item?.CarKMSCounterImagePath}`}
                    alt="Kms counter Image"
                    style={{ width: "100px" }}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                {t("search.noData")}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default InvoiceHistoryTable;
