/* eslint-disable comma-dangle */
import Api from "../../services";

export const createCar = (params, data) => {
  return (dispatch) => {
    return Api.diesalClients.car.createCar(params, data).then((response) => {
      dispatch({
        type: "CREATE_CAR",
        data: response,
      });
      return response;
    });
  };
};
export const getCarById = (id) => {
  return (dispatch) => {
    return Api.diesalClients.car.getCarById(id).then((response) => {
      dispatch({
        type: "CREATE_CAR",
        data: response,
      });
      return response;
    });
  };
};
export const updateCar = (params, data) => {
  return (dispatch) => {};
};
export const createDriver = (params, data) => {
  return (dispatch) => {
    return Api.diesalClients.driver
      .createDriver(params, data)
      .then((response) => {
        dispatch({
          type: "CREATE_Driver",
          data: response,
        });
        return response;
      });
  };
};
export const getDriverById = (id) => {
  return (dispatch) => {
    return Api.diesalClients.driver.getDriverById(id).then((response) => {
      dispatch({
        type: "GET_DRIVER",
        data: response,
      });
      return response;
    });
  };
};
export const deleteCar = (Id, params) => {
  return (dispatch) => {
    return Api.diesalClients.car.deleteCar(Id, params).then((response) => {
      dispatch({
        type: "DELETE_CAR",
        data: response,
      });
      return response;
    });
  };
};
export const deleteDriver = (Id, params) => {
  return (dispatch) => {
    return Api.diesalClients.driver
      .deleteDriver(Id, params)
      .then((response) => {
        dispatch({
          type: "DELETE_Driver",
          data: response,
        });
        return response;
      });
  };
};
export const createInvoice = (params, data) => {
  return (dispatch) => {
    return Api.diesalClients.Invoice.createInvoice(params, data).then(
      (response) => {
        dispatch({
          type: "CREATE_INVOICE",
          data: response,
        });
        return response;
      }
    );
  };
};
export const UpdateInvoice = (Id, params, data) => {
  return (dispatch) => {
    return Api.diesalClients.Invoice.updateInvoice(Id, params, data).then(
      (response) => {
        dispatch({
          type: "CREATE_INVOICE",
          data: response,
        });
        return response;
      }
    );
  };
};
